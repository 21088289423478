*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

/* html {
	font-size: 81.25%;
} */

body,
#app {
	box-sizing: border-box;
}

html,
body,
#app,
#app > div {
	height: 100%;
	width: 100%;
}
